import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from "@material-ui/core/styles"
import { useParams, withRouter } from 'react-router-dom'
import { toast } from 'react-toastify'
import classNames from 'classnames'

import FormLabel from "@material-ui/core/FormLabel"

import GridContainer from "components/Grid/GridContainer.js"
import GridItem from "components/Grid/GridItem.js"
import Card from "components/Card/Card.js"
import CardHeader from "components/Card/CardHeader.js"
import CardText from "components/Card/CardText.js"
import CardBody from "components/Card/CardBody.js"
import CustomInput from "components/CustomInput/CustomInput.js"
import Button from "components/CustomButtons/Button.js"

import DoorsSection from "./components/DoorsSection"
import EditLineItemModal from "./components/EditLineItemModal"
import FileUploadModal from "components/FileUploadModal"
import CustomerSection from "./components/CustomerSection"
import JobSection from "./components/JobSection"
import AFPListSection from "./components/AFPListSection"

import { UserRoleContext } from "context/UserRole/UserRoleContext"
import { checkUserPermission } from "utils/utils"
import { verifyString } from "utils/validations"
import { creditNumberError } from "variables/errors"
import styles from "assets/jss/material-dashboard-pro-react/views/creditCheckStyle"

import ApiServices from 'api/Api.js'

const useStyles = makeStyles(styles)

const CreditCheck = () => {

	const { jobId } = useParams()

	const classes = useStyles()

	const [jobData, setJobdata] = useState({})
	const [creditNumber, setCreditNumber] = useState()
	const [creditValidate, setCreditValidate] = useState(true)
	const [approveStatus, setApproveStatus] = useState(false)
	const [openEditModal, setOpenEditModal] = useState(false)
	const [lineItemId, setLineItemId] = useState("")
	const [approveError, setApproveError] = useState("")

	const [openFileUploadModal, setOpenFileUploadModal] = useState(false)

	const { userRole, permissions } = useContext(UserRoleContext)
	const [disabled, setDisabled] = useState(false)

	useEffect(() => {
		setDisabled(!checkUserPermission(permissions, "ApproveCreditCheck", userRole))
	}, [userRole, permissions])


	useEffect(async () => {
		fetchJobData()
	}, [])

	const fetchJobData = async () => {
		const res = await ApiServices.getJobById(jobId)
		if (res.status === 200) {
			setJobdata(res.data)
			!!res.data.creditCheck && setCreditNumber(res.data.creditCheck.number)
		}
	}

	const approveJob = async () => {
		setCreditValidate(verifyString(creditNumber))
		if (!verifyString(creditNumber)) {
			setApproveError(creditNumberError)
			toast.error(creditNumberError)
			return
		}
		setApproveError("")
		const res = await ApiServices.putJobCreditCheck({ jobId: jobId, creditCheckNumber: creditNumber })
		if (res.status === 201) {
			toast.success("Credit check saved.")
			setApproveStatus(true)
			fetchJobData()
		} else {
			toast.error("Something went wrong. Reload the page and try again?")
		}
	}

	const addVariantDoor = async (variant, type) => {
		if (type === "non_door") {
			let postData = {
				ascotRef: variant.ascotRef,
				costPrice: variant.costPrice,
				customDescription: variant.description,
				sellPrice: variant.sellPrice,
				stage: variant.stage,
				nonDoorVariation: {
					instructedBy: variant.instructedBy,
					instructionDate: variant.instructionDate,
					type: variant.variantType
				}
			}
			const res = await ApiServices.putNonDoorVariation(jobData.id.value, postData)
			if (res.status === 201) {
				await fetchJobData()
				toast.success("Variation added.")
			}
		} else if (type === "door") {
			let postData = {
				ascotRef: variant.ascotRef,
				costPrice: variant.costPrice,
				customDescription: variant.otherTypeDescription,
				sellPrice: variant.sellPrice,
				stage: variant.stage,
				nonDoorVariation: {
					instructedBy: variant.instructedBy,
					instructionDate: variant.instructionDate,
					type: 3
				},
				door: {
					type: variant.type,
					width: variant.width,
					height: variant.height,
					colour: variant.colour
				}
			}

			const res = await ApiServices.putDoorVariation(jobData.id.value, postData)

			if (res.status === 201) {
				await fetchJobData()
				toast.success("Door variation added.")
			}
		}
	}

	const addDoorLineItem = (newDoor) => {
		ApiServices.addDoors({
			jobId: jobData.id.value,
			lineItem: newDoor
		}).then((res) => {
			console.log(res)
			fetchJobData()
			toast.success("Door added.")
		})
			.catch(() => {
				toast.error("Failed")
			})
	}

	const removeLineItem = async (lineItemIndex) => {
		const res = await ApiServices.deleteLineItem(jobId, lineItemIndex)
		if (res.status === 200) {
			fetchJobData()
			toast.success("LineItem removed.")
		}
	}

	const toggleEditModal = () => {
		setOpenEditModal(!openEditModal)
	}

	const handleEditAction = (lineItemIndex) => {
		setLineItemId(lineItemIndex)
		toggleEditModal()
	}

	const handleUpdateLineItem = async (lineItem) => {
		const res = await ApiServices.editLineItem(jobId, lineItem.id, lineItem)
		if (res.status === 200) {
			toast.success("Door updated.")
			toggleEditModal()
		} else {
			toast.error("Something went wrong. Reload the page and try again?!")
		}
		fetchJobData()
	}

	const handleUploadFileBtn = (lineItemId) => {
		setLineItemId(lineItemId)
		setOpenFileUploadModal(true)
	}

	const uploadDesignFile = async (files) => {
		const _lineItem = jobData.lineItems.find(lineItem => lineItem.id === lineItemId)
		if (!_lineItem.isVariation) {
			ApiServices.uploadDoorDocuments(jobId, lineItemId, files[0].file)
				.then((res) => {
					if (res.status === 201) {
						toast.success("Design document successfully uploaded!")
					}
				})
				.catch(() => {
					toast.error("Faild to upload!")
				})
				.finally(() => {
					fetchJobData()
				})
		} else {
			ApiServices.uploadVariantDocuments(jobId, lineItemId, files[0].file)
				.then((res) => {
					if (res.status === 201) {
						toast.success("Design document successfully uploaded!")
					}
				})
				.catch(() => {
					toast.error("Faild to upload!")
				})
				.finally(() => {
					fetchJobData()
				})
		}
	}

	const updateNettOrDiscount = (nettOrDiscountBody) => {
		ApiServices.putJobNettOrDiscount({ jobId: jobId, nettOrDiscount: nettOrDiscountBody })
			.then((res) => {
				console.log(res)
				toast.success("NettOrDiscount Updated")
				fetchJobData()
			})
			.catch(() => {
				toast.error("Failed to update NettOrDiscount")
			})
	}

	return (
		<GridContainer>
			<CustomerSection customerData={jobData.customer} />
			<JobSection jobData={jobData} />
			<DoorsSection
				jobData={jobData}
				removeLineItem={removeLineItem}
				addVariantDoor={addVariantDoor}
				creditNumber={creditNumber}
				handleEditAction={handleEditAction}
				handleUploadFileBtn={handleUploadFileBtn}
				addDoorLineItem={addDoorLineItem}
				updateNettOrDiscount={updateNettOrDiscount}
			/>

			{jobData.stage > 0 && <AFPListSection />}

			<GridItem xs={12} sm={12} md={12}>
				<Card>
					<CardHeader color="rose" text>
						<CardText color="rose">
							<h4 className={classes.cardTitle}>Credit Check and Approval</h4>
						</CardText>
					</CardHeader>
					<CardBody className={classes.cardBodyPadding}>
						<GridContainer className={classes.justifyCenter}>
							<GridItem xs={12} sm={12} md={6}>
								<FormLabel>
									Credit Check Number*
								</FormLabel>
								<CustomInput
									formControlProps={{
										fullWidth: true,
										className: classes.paddingTop
									}}
									inputProps={{
										type: "text",
										style: { fontSize: 20 },
										placeholder: "Credit Check Number",
										name: "creditNumber",
										value: creditNumber ? creditNumber : '',
										onChange: (e) => { e.preventDefault(); setCreditNumber(e.target.value) },
										readOnly: approveStatus || disabled
									}}
									error={!creditValidate}
									helperText={approveError}
								/>
							</GridItem>
							<GridItem xs={12} sm={6} md={3} className={classes.actionBtn}>
								<Button
									color="info"
									size="lg"
									fullWidth
									className={classNames(classes.addDoorBtn, classes.approveBtn)}
									onClick={approveJob}
									disabled={disabled}
								>
									APPROVE ORDER
								</Button>
							</GridItem>
						</GridContainer>
					</CardBody>
				</Card>
				<EditLineItemModal
					open={openEditModal}
					toggleModal={toggleEditModal}
					jobId={jobId}
					lineItemId={lineItemId}
					lineItem={jobData.lineItems ? jobData.lineItems.find(lineItem => lineItem.id === lineItemId) : {}}
					handleUpdateLineItem={handleUpdateLineItem}
				/>
				<FileUploadModal
					open={openFileUploadModal}
					handleClose={() => setOpenFileUploadModal(false)}
					handleSubmit={(files) => {
						uploadDesignFile(files)
						setOpenFileUploadModal(false)
					}}
				/>
			</GridItem>
		</GridContainer>
	)
}

export default withRouter(CreditCheck)
