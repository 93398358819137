import React, { useState, useEffect } from 'react'
import SweetAlert from "react-bootstrap-sweetalert"
import { makeStyles } from "@material-ui/core/styles"

import clsx from 'clsx';
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Collapse from '@material-ui/core/Collapse'

import GridItem from "components/Grid/GridItem.js"
import GridContainer from 'components/Grid/GridContainer'
import OrderItemHeader from "./OrderItemHeader"
import LineItem from "./LineItem"
import LineItemsHeader from "./LineItemsHeader"
import VariantForm from "./VariantForm"
import Button from "components/CustomButtons/Button.js"

import {
	order_status_filter_list
} from "variables/dropdown.js"

import styles from "assets/jss/material-dashboard-pro-react/views/orderItemCollapseStyle.js"


const useStyles = makeStyles(styles)

function OrderItemCollapse(props) {

	const {
		jobData,
		addVariantDoor,
		handleCancelDoors,
		lineItemTryStageRevert,
		handleUploadBtn,
		handleCompleteDoors
	} = props

	const [alert, setAlert] = React.useState(null)
	const [expanded, setExpanded] = useState(false)
	const [allCompleted, setAllCompleted] = useState(false)
	const [allCancelled, setAllCancelled] = useState(false)
	const [showVariantForm, setShowVariantForm] = useState(false)
	const [cancelItemIds, setCancelItemIds] = useState([])
	const [stageCompleteIds, setStageCompleteIds] = useState([])


	const handleExpandClick = () => {
		setExpanded(!expanded);
	}

	const handleChangeAllComplete = (e) => {
		let lineItemList
		if (e.target.checked) {
			lineItemList = jobData.lineItems.filter(lineItem => lineItem.isDoor ? lineItem.door.stage !== 5 : lineItem.nonDoorVariation.stage !== 5)
			let lineItemIdList = lineItemList.map(lineItem => lineItem.id)
			setStageCompleteIds(lineItemIdList)
		} else {
			setStageCompleteIds([])
		}
		setAllCompleted(e.target.checked)
	}

	const handleChangeAllCancel = (e) => {
		setAllCancelled(e.target.checked)
		if (e.target.checked) {
			let door_ids = []
			door_ids = jobData.lineItems.map(lineItem => {
				if (lineItem.isDoor) {
					if (lineItem.door.stage !== 6) {
						return lineItem.id
					}
				}
			})
			setCancelItemIds(door_ids.filter(door_id => door_id !== undefined))
		} else {
			setCancelItemIds([])
		}
	}

	const handleChangeCancel = (e, lineItemId) => {
		if (e.target.checked) {
			setCancelItemIds([...cancelItemIds, lineItemId])
		} else {
			setCancelItemIds(cancelItemIds.filter(id => id !== lineItemId))
		}
	}

	const handleChangeComplete = (e, lineItemId) => {
		if (e.target.checked) {
			setStageCompleteIds([...stageCompleteIds, lineItemId])
		} else {
			setStageCompleteIds(stageCompleteIds.filter(id => id !== lineItemId))
		}
	}

	const openVariantForm = (e) => {
		e.preventDefault()
		setShowVariantForm(true)
	}

	/*
			Add variant
	*/
	const handleAddVariant = (variant, type) => {
		setAlert(
			<SweetAlert
				warning
				style={{ display: "block", marginTop: "-100px" }}
				title="Add Variant?"
				onConfirm={() => { hideAlert(); addVariantDoor(variant, type, jobData.id.value); setShowVariantForm(false) }}
				onCancel={() => { hideAlert() }}
				confirmBtnCssClass={classes.button + " " + classes.success + " " + classes.alertButton}
				cancelBtnCssClass={classes.button + " " + classes.danger + " " + classes.alertButton}
				confirmBtnText="Yes"
				cancelBtnText="Cancel"
				showCancel
			>
			</SweetAlert>
		)
	}

	const ConfirmStageDoneAlert = () => {
		setAlert(
			<SweetAlert
				warning
				style={{ display: "block", marginTop: "-100px" }}
				title="Confirm done?"
				onConfirm={() => {hideAlert(); handleCompleteDoors(jobData.id.value, stageCompleteIds); setStageCompleteIds([]) }}
				onCancel={() => { hideAlert() }}
				confirmBtnCssClass={classes.button + " " + classes.success + " " + classes.alertButton}
				cancelBtnCssClass={classes.button + " " + classes.danger + " " + classes.alertButton}
				confirmBtnText="Yes"
				cancelBtnText="Cancel"
				showCancel
			>
			</SweetAlert>
		)
	}

	const hideAlert = () => {
		setAlert(null);
	}

	const classes = useStyles()

	useEffect(() => {
		setAllCompleted(false)
		setAllCancelled(false)
		setCancelItemIds([])
	}, [jobData, handleCompleteDoors, lineItemTryStageRevert])

	return (
		<Card className={classes.wrapper}>
			{alert}
			<CardContent className={classes.cardContent}>
				<OrderItemHeader
					handleExpandClick={handleExpandClick}
					expanded={expanded}
					classeNames={clsx(classes.expand, {
						[classes.expandOpen]: expanded,
					})}
					job={jobData}
				/>
			</CardContent>
			<Collapse in={expanded} timeout="auto" unmountOnExit>
				{
					jobData.lineItems === null ?
						<CardContent></CardContent>
						:
						<CardContent>
							{
								jobData.lineItems.length === 0 || jobData.stage === 0 ? <></> :
									<LineItemsHeader
										handleChangeAllComplete={handleChangeAllComplete}
										handleChangeAllCancel={handleChangeAllCancel}
										orderId={jobData.id.value}
										allCompleted={allCompleted}
										allCancelled={allCancelled}
									/>
							}
							{
								jobData.lineItems.map((lineItem, index) => (
									<LineItem key={index}
										orderId={jobData.id.value}
										lineItem={lineItem}
										allCompleted={allCompleted}
										cancelItemIds={cancelItemIds}
										handleChangeCancel={handleChangeCancel}
										handleChangeComplete={handleChangeComplete}
										lineItemTryStageRevert={lineItemTryStageRevert}
										handleUploadBtn={handleUploadBtn}
										stageCompleteIds={stageCompleteIds}
									/>
								))
							}
							{
								showVariantForm ? <VariantForm handleAddVariant={handleAddVariant} /> : <></>
							}
							{
								order_status_filter_list.find(item => item.value === jobData.stage).text === "In Progress" ?
									<GridContainer>
										<GridItem xs={12} sm={4} md={6} className={classes.lineItemsHeaderCell + ' ' + classes.displayFlex}>
											<Button
												className={classes.actionBtn + " " + classes.marginRight}
												onClick={(e) => openVariantForm(e)}
												color="info"
											>
												Add Variant …
											</Button>
											{
												stageCompleteIds.length > 0 && (
													<Button
														className={classes.actionBtn + " " + classes.marginRight}
														onClick={ ConfirmStageDoneAlert }
														color="info"
													>
														Stage Complete
													</Button>
												)
											}

											{
												cancelItemIds.length !== 0 && (
													<Button className={classes.actionBtn} color="rose" onClick={() => { handleCancelDoors(jobData.id.value, cancelItemIds) }}>
														Cancel
													</Button>
												)
											}
										</GridItem>
									</GridContainer>
									: <></>
							}
						</CardContent>
				}
			</Collapse>
		</Card>
	)
}

export default OrderItemCollapse
