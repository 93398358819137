import React, {createContext, useEffect, useState} from 'react'
import ApiServices from "api/Api.js"
import {useMsal} from '@azure/msal-react'
import jwt_decode from "jwt-decode";

export const UserRoleContext = createContext()

const UserRoleContextProvider = ({ children }) => {

  const { accounts,instance,inProgress } = useMsal();
	const [userRole, setUserRole] = useState("ReadOnly")
	const [rolesAvailableToUser, setRolesAvailableToUser] = useState([{value: "ReadOnly", text: "ReadOnly"}])
	const [permissions, setPermissions] = useState()
	const changeUserRole = (role) => setUserRole(role)

	const contextValue = {
		userRole,
		permissions,
		changeUserRole,
		rolesAvailableToUser
	}

	const useJWTTokenRoles=true

	const getPermissionList = async () => {

		try {
			const res = await ApiServices.getPermissionsAndRoles(accounts[0].username)
			if (res.status === 200) {
				console.log("UserRoleContext:Got Permission list and Backend roles:",res.data)
				setPermissions(res.data.permissions)
				if(!useJWTTokenRoles) {
					console.log("UserRoleContext:Using roles from server for ", accounts[0].username)
					setUserRole(res.data.roles[0])
					setRolesAvailableToUser(res.data.roles.map(r => ({value: r, text: r})))
				}else{
					console.log("UserRoleContext:But not using roles from server")
				}
			}
			else {
				console.error("UserRole.getPermissionsAndRoles()",accounts[0], res.status, "accessToken", sessionStorage.getItem("accessToken"))
			}
		}
		catch(e){console.error("UserRole.getPermissionList:",e)	}
	}

	useEffect(async () => {
		let roles = jwt_decode(localStorage.getItem('accessToken')).roles
		const defaultRole = roles.filter((role) => role.startsWith('Default' || role.startsWith('default')))[0].replace('Default', '');
		if(!roles.includes(defaultRole)){ roles.unshift(defaultRole) }
		const allUserRoles = roles.filter((cell) => !cell.includes('Default')).map(r => ({value: r, text: r}));
		if(useJWTTokenRoles) {
			console.log("UserRoleContext:Got Default Role",defaultRole, "and all roles from jwt token", allUserRoles)
			setUserRole(defaultRole)
			setRolesAvailableToUser(allUserRoles)
		}
		await getPermissionList()
	}, [accounts,instance,inProgress])

	return (
		<UserRoleContext.Provider value={contextValue}>
			{
				children
			}
		</UserRoleContext.Provider>
	)
}

export default UserRoleContextProvider
