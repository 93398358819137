export const customerErrors = {
  name: "customer name",
  city: "city",
  postcode: "postcode",
  address_lines: "address lines"
}

export const jobErrors = {
  address_lines: "address lines",
  areaCode: "area code",
  city: "city",
  installationDateWc: "installation date wx",
  distribution: "distribution",
  hoisting: "hoisting",
  offload: "offload",
  parkingOnSite: "parking on site",
  plant: "plant",        
  power: "power",
  protection: "protection",
  qsContact: "qs contact",
  qsContactPhone: "qs contact phone",
  siteContact: "site contact",
  siteContactPhone: "site contact phone",
  numVisits: "num visits",
  siteEmail: "site email",
  qsEmail: "qs email",
  vatCode: "vat code"
}

export const doorErrors = {
  type: "door type",
  width: "width",
  height: "height",
  costPrice: "cost price",
  sellPrice: "sell price",
  colour: "colour",
  no_doors: "no doors",
  otherTypeDescription: "other type description",
  customerDoorRef: "customer door ref",
  customerRef: "customer door ref"
}

export const variantErrors = {
  variantType: "variant type", 
  description: "description", 
  costPrice: "cost price", 
  sellPrice: "sell price",
  instructedBy: "instructed by", 
  instructionDate: "instruction date", 
  door_type: "door type",
  otherTypeDescription: "other type description", 
  width: "width", 
  height: "height", 
  colour: "colour"
}
export const creditNumberError = "credit check number"

export const vatRateErrors = {
  code: "code",
  percent: "percent",
  startDate: "startDate",
  description: "Description"
}

export const nettOrDiscountErrors = {
  total_price_correct: "total pirce is correct",
  nett_less_discount: "net less discount",
  discount: "discount",
  other_discount: "other discount"
}

export const editCustomerErrors = {
  name: "name",
  address_lines: "address lines",
  city: "city"
}

export const changeJobNumberErrors = {
  jobId: "jobId",
  newJobId: "new job id"
}
