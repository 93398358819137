export const formatCurrency = (amount) => {
	return (parseInt(amount)).toLocaleString('en-US', {
		style: 'currency',
		currency: 'GBP',
	})
}

export const checkUserPermission = (permissions, permission, role) => {
	if (permissions !== undefined) {
		return permissions[permission].includes(role)
	}
	return false
}

export const errorMessage = (errors) => {
	return (
		<div>
			{
				Object.keys(errors).map(key => <p key={key}>{errors[key]}</p>)
			}
		</div>
	)
}

Date.prototype.toISODateString = function () { return this.toLocaleString("en-CA", { year: 'numeric', month: '2-digit', day: '2-digit' }) }

export const getVariantTypeText = (variantType) => {
	if (variantType === 0) {
		return "Non Door Variation"
	} else if (variantType === 1) {
		return ("Installation Variation")
	} else if (variantType === 2) {
		return ("Cancellation")
	} else if (variantType === 3) {
		return "New Door Variation"
	}
}

export const isShortUserMessage = (exception) =>
				exception &&
				typeof(exception)==='string' &&
				exception.length>0 &&
				exception.length<2000 &&
				(exception.match(/\n|\r/g)||[]).length <3

export const assertElseWarn = (condition,message,...args)=>{
	if(condition)return condition;
	console.warn(message??"assert failed",args)
	return false
}
export const assertElseError = (condition,message,...args)=>{
	if(condition)return condition;
	console.error(message??"assert failed",args)
	return false
}
