import {
	customerErrors,
	jobErrors,
	doorErrors,
	variantErrors,
	vatRateErrors,
	nettOrDiscountErrors,
	editCustomerErrors,
	changeJobNumberErrors
} from "variables/errors"

export const verifyEmail = value => {
	var emailRex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	if (emailRex.test(value)) {
		return true
	}
	return false
};

export const verifyPostCode = value => {
	var postcodeRex = /(^\d{5}$)|(^\d{5}-\d{4}$)/
	if (postcodeRex.test(value)) {
		return true
	}
	return false
}

export const verifyString = value => {
	if (value === undefined || value === null) {
		return false
	}
	if (value.toString().trim().length === 0) {
		return false
	}
	return true
}

export const verifyNotNullOrUndefined = value => {
	if (value === undefined || value === null) {
		return false
	}
	return true
}


export const verifyNumber = value => {
	return !!parseFloat(value)
}

export const verifyDate = value => {
	if (!Date.parse(value)) return false
	if (new Date(value).getFullYear() < 1901) return false
	return true
}

export const initialCustomerFormValidation = {
	name: true,
	city: true,
	postcode: true,
	address_lines: true,
}

export const verifyCustomerForm = customer => {
	let verifyResult = {
		name: verifyString(customer.name),
		city: verifyString(customer.city),
		postcode: verifyString(customer.postcode),
		address_lines: verifyString(customer.address_lines),
	}

	let verifyStatus = true
	let errors = {}
	for (const [key, val] of Object.entries(verifyResult)) {
		if (val === false) {
			verifyStatus = false
			errors[key] = customerErrors[key]
		}
	}

	return { verifyStatus, verifyResult, errors }
}

export const initialNewJobFormValidation = {
	address_lines: true, areaCode: true, city: true, installationDateWc: true, distribution: true,
	hoisting: true, offload: true, parkingOnSite: true,
	plant: true, postcode: true, power: true, protection: true,
	qsContact: true, qsContactPhone: true, siteContact: true,
	siteContactPhone: true, numVisits: true, siteEmail: true, qsEmail: true, vatCode: true
}

export const verifyNewJobForm = newJob => {
	let verifyResult = {
		address_lines: verifyString(newJob.address_lines),
		areaCode: verifyString(newJob.areaCode),
		city: verifyString(newJob.city),
		installationDateWc: verifyDate(newJob.installationDateWc),
		distribution: newJob.distribution === null ? false : !isNaN(newJob.distribution),
		hoisting: newJob.hoisting === null ? false : !isNaN(newJob.hoisting),
		offload: newJob.offload === null ? false : !isNaN(newJob.offload),
		parkingOnSite: newJob.parkingOnSite === null ? false : !isNaN(newJob.parkingOnSite),
		plant: newJob.plant === null ? false : !isNaN(newJob.plant),
		power: newJob.power === null ? false : !isNaN(newJob.power),
		protection: newJob.protection === null ? false : !isNaN(newJob.protection),
		qsContact: verifyString(newJob.qsContact),
		qsContactPhone: verifyString(newJob.qsContactPhone),
		siteContact: verifyString(newJob.siteContact),
		siteContactPhone: verifyString(newJob.siteContactPhone),
		numVisits: verifyString(newJob.numVisits ? newJob.numVisits.toString() : ""),
		siteEmail: verifyString(newJob.siteEmail) ? verifyEmail(newJob.siteEmail) : true,
		qsEmail: verifyString(newJob.qsEmail) ? verifyEmail(newJob.qsEmail) : true,
		vatCode: verifyString(newJob.vatCode)
	}

	let verifyStatus = true
	let errors = {}

	for (const [key, val] of Object.entries(verifyResult)) {
		if (val === false) {
			verifyStatus = false
			errors[key] = jobErrors[key]
		}
	}

	return { verifyStatus, verifyResult, errors }
}

export const initialNewDoorFormValidation = {
	type: true, otherTypeDescription: true, width: true, height: true,
	costPrice: true, sellPrice: true, colour: true, no_doors: true, customerDoorRefList: []
}

export const verifyNewDoorForm = newDoor => {
	let verifyResult = {
		type: verifyString(newDoor.type.toString()),
		width: verifyNumber(newDoor.width),
		height: verifyNumber(newDoor.height),
		costPrice: verifyString(newDoor.costPrice),
		sellPrice: verifyString(newDoor.sellPrice),
		colour: verifyString(newDoor.colour),
		no_doors: verifyString(newDoor.no_doors),
		otherTypeDescription: newDoor.type === 24 ? verifyString(newDoor.otherTypeDescription) : true,
		customerDoorRefList: []
	}
	let errors = {}
	let customerDoorRefListValidation = new Array(newDoor.no_doors)

	for (let i = 0; i < newDoor.no_doors; i++) {
		customerDoorRefListValidation[i] = verifyString(newDoor.customerDoorRefList[i])
	}

	verifyResult["customerDoorRefList"] = customerDoorRefListValidation
	let status = true

	for (const [key, val] of Object.entries(verifyResult)) {
		if (key !== "customerDoorRefList") {
			if (val === false) {
				status = false
				errors[key] = doorErrors[key]
			}
		}
	}
	if (verifyResult.customerDoorRefList.includes(false)) {
		status = false
		errors['customerDoorRef'] = doorErrors['customerDoorRef']
	}
	return { status, verifyResult, errors }
}

export const initialNettOrDiscountValidation = {
	total_price_correct: true, nett_less_discount: true, discount: true, other_discount: true
}

export const verifyNettOrDiscountForm = (nettOrDiscount) => {
	console.log(nettOrDiscount)
	let verifyResult = {
		total_price_correct: nettOrDiscount.total_price_correct === "yes" ? true : false,
		nett_less_discount: verifyString(nettOrDiscount.nett_less_discount),
		discount: nettOrDiscount.nett_less_discount === "less" ? verifyString(nettOrDiscount.discount) : true,
		other_discount: (nettOrDiscount.nett_less_discount === "less") && (nettOrDiscount.discount === "other") ? verifyString(nettOrDiscount.other_discount) : true
	}

	let errors = {}
	let status = true
	for (const [key, val] of Object.entries(verifyResult)) {
		if (val === false) {
			status = false
			errors[key] = nettOrDiscountErrors[key]
		}
	}

	return { status, verifyResult, errors }
}

export const initialVariantFormValidation = {
	variantType: true, description: true, costPrice: true, sellPrice: true,
	instructedBy: true, instructionDate: true, door_type: true,
	otherTypeDescription: true, width: true, height: true, colour: true
}

export const verifyVariantForm = newVariant => {

	let verifyResult = initialVariantFormValidation
	verifyResult.variantType = verifyString(newVariant.variantType)
	verifyResult.costPrice = verifyString(newVariant.costPrice)
	verifyResult.sellPrice = verifyString(newVariant.sellPrice)
	verifyResult.instructedBy = verifyString(newVariant.instructedBy)
	verifyResult.instructionDate = verifyDate(newVariant.instructionDate)

	if (newVariant.variantType === 'new_door_variation') {
		verifyResult.width = verifyString(newVariant.width)
		verifyResult.door_type = verifyNotNullOrUndefined(newVariant.door_type )
		verifyResult.height = verifyString(newVariant.height)
		verifyResult.colour = verifyString(newVariant.colour)
		verifyResult.otherTypeDescription = newVariant.door_type === 24 ? verifyString(newVariant.otherTypeDescription) : true
	} else {
		verifyResult.description = verifyString(newVariant.description)
	}
	let verifyStatus = true
	let errors = {}
	for (const [key, val] of Object.entries(verifyResult)) {
		if (val === false) {
			verifyStatus = false
			errors[key] = variantErrors[key]
		}
	}

	return { verifyStatus, verifyResult, errors }
}

export const initialAddVatRateFormValidation = {
	code: true,
	percent: true,
	startDate: true,
	description: true
}

export const verifyVatRateForm = (newVatRate) => {
	let verifyResult = initialAddVatRateFormValidation
	verifyResult.code = verifyString(newVatRate.code)
	verifyResult.percent = verifyString(newVatRate.percent)
	verifyResult.startDate = verifyDate(newVatRate.startDate)
	verifyResult.description = verifyString(newVatRate.description)

	let verifyStatus = true
	let errors = {}
	for (const [key, val] of Object.entries(verifyResult)) {
		if (val === false) {
			verifyStatus = false
			errors[key] = vatRateErrors[key]
		}
	}
	return { verifyStatus, verifyResult, errors }
}

export const initialAwaitingNewDoorFormValidation = {
	type: true, otherTypeDescription: true, width: true, height: true,
	costPrice: true, sellPrice: true, colour: true, customerRef: true
}

export const verifyAwaitingNewDoorForm = newDoor => {
	let verifyResult = {
		type: verifyString(newDoor.type),
		width: verifyNumber(newDoor.width),
		height: verifyNumber(newDoor.height),
		costPrice: verifyString(newDoor.costPrice),
		sellPrice: verifyString(newDoor.sellPrice),
		colour: verifyString(newDoor.colour),
		otherTypeDescription: newDoor.type === 24 ? verifyString(newDoor.otherTypeDescription) : true,
		customerRef: verifyString(newDoor.customerRef)
	}
	let errors = {}

	let verifyStatus = true

	for (const [key, val] of Object.entries(verifyResult)) {
		if (val === false) {
			verifyStatus = false
			errors[key] = doorErrors[key]
		}
	}

	return { verifyStatus, verifyResult, errors }
}

export const initialEditCustomerValidation = {
	name: true,
	city: true,
	address_lines: true
}

export const verifyEditCustomerForm = customer => {
	let verifyResult = {
		name: verifyString(customer.name),
		address_lines: verifyString(customer.address_lines),
		city: verifyString(customer.city)
	}
	let errors = {}

	let verifyStatus = true

	for (const [key, val] of Object.entries(verifyResult)) {
		if (val === false) {
			verifyStatus = false
			errors[key] = editCustomerErrors[key]
		}
	}

	return { verifyStatus, verifyResult, errors }
}

export const initialChangeJobNumberValidation = {
	jobId: true,
	newJobId: true
}

export const verifyChangeJobNumberForm = jobIds => {
	let verifyResult = {
		jobId: verifyNumber(jobIds.jobId),
		newJobId: verifyNumber(jobIds.newJobId)
	}

	let verifyStatus = true
	let errors = {}

	for (const [key, val] of Object.entries(verifyResult)) {
		if (val === false) {
			verifyStatus = false
			errors[key] = changeJobNumberErrors[key]
		}
	}

	return { verifyStatus, verifyResult, errors }
}
